<template>
<div>
  <bar title="true" closeActivation="true"/>

  <v-content>
    <message/>
<v-container>
	<v-form ref="form">
		<v-layout align-center justify-center row fill-height>

	      <v-flex offset-md2 md6 offset-sm2 sm6 xs7 ml-3>
	        <v-text-field :disabled="sended" label="Password" :rules="[validateRequired, validatePassword]" type="password" v-model="password"></v-text-field>
	      </v-flex>

	      <v-flex md2 sm2 xs5 mr-3>
          <v-btn v-show="!sended" @click="action" :loading="loading" fab dark large color="primary">
            <v-icon dark>mdi-send</v-icon>
          </v-btn>
         	<v-btn v-show="sended" @click="start" dark large color="success">
            Ir al login
          </v-btn>
	      </v-flex>

		</v-layout>
	</v-form>
</v-container>
  </v-content>

  <pl-footer/>
</div>

</template>

<script>

import bar from '../components/appComponents/Bar.vue'
import message from '../components/appComponents/Message.vue'
import footer from '../components/appComponents/Footer.vue'

export default {
  name: 'Password',
  data() {
    return {
      password: '',
      sended: false,
      hash: '',
      loading: false
    }
  },
  components: {
    'pl-footer': footer,
    message,
    bar
  },
  mounted(){
  	if (this.$route.params.hash.length !== 6){
    	this.start();
  	}
  	this.hash = this.$route.params.hash;
  	this.$store.state.playMode = false;
    this.$store.state.loginView = false;
    this.$store.state.logoutView = false;
    this.$store.state.registerView = true;
    let messageText = this.$route.name === 'register' ? 'Elige tu contraseña para completar el proceso de registro' : 'Elige una nueva contraseña'
    this.$root.$emit('changeMessage', { show : true , text : messageText } )
  },
  methods: {
    start: function () {
      this.$store.state.currentRoute = '/start/login';
      this.$router.push(this.$store.state.currentRoute);
    },
    action: function () {
      let valid = this.$refs.form.validate();
      if (valid) {
        this.loading = true;

        let params = { 
              hash: this.hash
          ,   password: this.password 
        };
        this.$store.commit('recalculateCaptcha')
        let config = { headers: {
              Accept: 'application/json',
              'Captcha-Authorization': this.$store.state.captchaTokenResult
        }};

        let success = function(response){
          let message = this.$route.name === 'register' ? 'Has finalizado el proceso de registro, ve al login para iniciar sesión' : 'Tu contraseña ha sido modificada, ve al login para iniciar sesión';
          this.$root.$emit('changeMessage', { show : true , text : message } )
          this.sended = true;
          this.loading = false;
        };

        let error = function(response) {
          let message = 'Ocurrió un error inesperado, inténtelo más tarde';
          if (response.body.error === 'token_is_expired' ) {
            message = 'Han pasado más de 15 minutos desde que te enviamos el email. Vuelve a solicitar el email y completa la acción antes de 15 minutos';
          } else if(response.body.error === 'token_does_not_exist'){
            message = 'No ha sido necesario realizar la acción, es posible que ya la realizaras en el pasado';
          }
          this.$root.$emit('changeSnackbar', { show : true , text : message, timeout: 4000, type: 'warning' } )
          this.loading = false;
        }

        if (this.$route.name === 'register'){
          this.$http.post(this.$store.state.urlApi+'user', params,config).then(success, error);
        } else {
          this.$http.patch(this.$store.state.urlApi+'user', params,config).then(success, error);
        }
      
         
      } 
    },
    validateRequired: function(value){
      return !!value || 'obligatorio'
    },
    validatePassword: function(value){
      return (value.length >= 6 && value.length <= 10) || 'Longitud entre 6 y 10'
    }
  }
}
</script>

<style>

</style>
